<form (ngSubmit)="save()" [formGroup]="form">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>

    <h2 *ngIf="form.get('nombre').value"
        class="headline m-0"
        fxFlex="auto">{{ form.get('nombre').value }}</h2>
    <h2 *ngIf="!form.get('nombre').value"
        class="headline m-0"
        fxFlex="auto">Nueva Reserva</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-content fxLayout="column">

    <mat-form-field *ngIf='this.mode !== "update"'>
      <mat-label>Negocio</mat-label>
      <input [matAutocomplete]="auto" [formControl]="negocioCtrl" matInput>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let negocio of filtered | async " [value]="negocio.id">
          <!-- <img [src]="cliente.imageSrc" class="align-middle mr-4 shadow-6 imagen-select-cliente"> -->
          <span>{{ negocio.nombre }}</span>
        </mat-option>
      </mat-autocomplete>

      <button class="text-secondary" mat-icon-button matSuffix type="button">
        <mat-icon [icIcon]="icArrowDropDown"></mat-icon>
      </button>
    </mat-form-field>

  <div class="flex flex-col sm:flex-row">
    
    <!-- <mat-form-field class="flex-auto sm:mr-3">
      <mat-label>Reservada el</mat-label>
      <input [matDatepicker]="datepickerRef" matInput>
      <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #datepickerRef></mat-datepicker>
    </mat-form-field> -->

    <mat-form-field appearance="fill">
      <mat-label>Seleccione las fechas</mat-label>
      <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
        <input matStartDate formControlName="fechaEntrada" placeholder="Llegada">
        <input matEndDate formControlName="fechaSalida" placeholder="Salida">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    
      <mat-error *ngIf="form.controls.fechaEntrada.hasError('matStartDateInvalid')">Fecha de llegada incorrecta</mat-error>
      <mat-error *ngIf="form.controls.fechaSalida.hasError('matEndDateInvalid')">Fecha de salida incorrecta</mat-error>
    </mat-form-field>

    <mat-form-field class="flex-auto sm:ml-3">
      <mat-label>Nombre*</mat-label>
      <input formControlName="nombre" matInput>

      <mat-icon [icIcon]="icPerson" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>
  </div>    
    
    

    <div class="flex flex-col sm:flex-row">     

      <mat-form-field class="flex-auto">
        <mat-label>País*</mat-label>
        <input formControlName="pais" matInput>

        <mat-icon [icIcon]="icEditLocation" class="mr-3" matPrefix></mat-icon>
      </mat-form-field>

      <mat-form-field class="vex-flex-form-field sm:ml-3">
        <mat-label>PAX</mat-label>
        <mat-select formControlName="cantidadPersonas">
          <mat-option *ngFor="let option of huespedes" [value]="option">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="sm:ml-3 flex-auto">
        <mat-label>Precio*</mat-label>
        <input formControlName="precio" matInput>

        <mat-icon [icIcon]="icEditLocation" class="mr-3" matPrefix></mat-icon>
      </mat-form-field>
    </div>

    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Datos</mat-label>
      <textarea matInput placeholder="Quiere montar a caballo..." formControlName="datos" ></textarea>
    </mat-form-field>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">CANCELAR</button>
    <button *ngIf="isCreateMode() && form.valid " color="primary" mat-button type="submit">CREAR</button>
    <button *ngIf="isUpdateMode()" color="primary" mat-button type="submit">ACTUALIZAR</button>
  </mat-dialog-actions>
</form>

<!-- <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon [icIcon]="icPrint"></mat-icon>
    <span>Print</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icDownload"></mat-icon>
    <span>Export</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icDelete"></mat-icon>
    <span>Delete</span>
  </button>
</mat-menu> -->
