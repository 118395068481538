<form (ngSubmit)="save()" [formGroup]="form">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <img *ngIf="urlImagen" [src]="urlImagen" class="avatar mr-5">

    <h2 *ngIf="form.get('nombre').value || form.get('apellidos').value"
        class="headline m-0"
        fxFlex="auto">{{ form.get('nombre').value + ' ' + form.get('apellidos').value }}</h2>
    <h2 *ngIf="!form.get('nombre').value && !form.get('apellidos').value"
        class="headline m-0"
        fxFlex="auto">Nuevo Cliente</h2>

    <!-- <button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon [icIcon]="icMoreVert"></mat-icon>
    </button> -->

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-content fxLayout="column">
    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="mt-6 flex-auto">
        <mat-label>Nombre</mat-label>
        <input cdkFocusInitial formControlName="nombre" matInput>

        <mat-icon [icIcon]="icPerson" class="mr-3" matPrefix></mat-icon>
      </mat-form-field>

      <mat-form-field class="sm:mt-6 sm:ml-6 flex-auto">
        <mat-label>Apellidos</mat-label>
        <input cdkFocusInitial formControlName="apellidos" matInput>

        <mat-icon [icIcon]="icPerson" class="mr-3" matPrefix></mat-icon>
      </mat-form-field>
    </div>

    <mat-form-field class="flex-auto">
      <mat-label>Carné de identidad</mat-label>
      <input formControlName="ci" matInput>

      <mat-icon [icIcon]="icEditLocation" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Teléfono</mat-label>
      <input formControlName="telefono" matInput>

      <mat-icon [icIcon]="icPhone" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Dirección</mat-label>
      <input formControlName="direccion" matInput>

      <mat-icon [icIcon]="icEditLocation" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Localidad</mat-label>
      <input formControlName="localidad" matInput>

      <mat-icon [icIcon]="icEditLocation" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Correo</mat-label>
      <input formControlName="correo" matInput>

      <mat-icon [icIcon]="icMail" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Correo Personal</mat-label>
      <input formControlName="correoPersonal" matInput>

      <mat-icon [icIcon]="icMail" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Airbnb</mat-label>
      <input formControlName="passwordAirbnb" matInput>

      <mat-icon [icIcon]="icMail" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Gmail</mat-label>
      <input formControlName="passwordGmail" matInput>

      <mat-icon [icIcon]="icMail" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>

    <div class="flex flex-col sm:flex-row">
      <mat-slide-toggle [checked]="true" color="primary" labelPosition="before" formControlName="activo">Activo
      </mat-slide-toggle>
      
    </div>
    <div class="flex flex-col sm:flex-row">
      <span style="margin-right: 10px; display: flex; align-items: center;">% a pagar</span>
      <mat-slider [max]="100" formControlName="porcientoAPagar" [min]="0" [step]="1" [thumbLabel]="true" [tickInterval]="10" value="10"></mat-slider>
    </div>

    <!-- <div class="flex flex-col sm:flex-row">
      <mat-form-field class="flex-auto">
        <mat-label>Zipcode</mat-label>
        <input formControlName="zipcode" matInput>

        <mat-icon [icIcon]="icMyLocation" class="mr-3" matPrefix></mat-icon>
      </mat-form-field>

      <mat-form-field class="sm:ml-6 flex-auto">
        <mat-label>City</mat-label>
        <input formControlName="city" matInput>

        <mat-icon [icIcon]="icLocationCity" class="mr-3" matPrefix></mat-icon>
      </mat-form-field>
    </div> -->


    <!-- <mat-form-field class="flex-auto">
      <mat-label>Notes</mat-label>
      <textarea formControlName="notes" matInput></textarea>
    </mat-form-field> -->
    <div class="flex-auto .mat-form-field-wrapper" *ngIf="isCreateMode()">
      <img [src]="urlImagen" style="width: 128px; height: 128px; margin-bottom: 20px; object-fit: contain" *ngIf="urlImagen">
      <button type="button" mat-raised-button (click)="fileInput.click()" style="margin-bottom: 20px;">Foto</button>
      <input hidden (change)="onFileSelected()" #fileInput type="file" id="file" formControlName="imageSrc">
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">CANCELAR</button>
    <button *ngIf="isCreateMode()" color="primary" mat-button type="submit">CREAR</button>
    <button *ngIf="isUpdateMode()" color="primary" mat-button type="submit">ACTUALIZAR</button>
  </mat-dialog-actions>
</form>

<!-- <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon [icIcon]="icPrint"></mat-icon>
    <span>Print</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icDownload"></mat-icon>
    <span>Export</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icDelete"></mat-icon>
    <span>Delete</span>
  </button>
</mat-menu> -->
