import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Negocio } from '../interfaces/negocio.model';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPerson from '@iconify/icons-ic/twotone-person';
import icMail from '@iconify/icons-ic/mail';
import icMyLocation from '@iconify/icons-ic/twotone-my-location';
import icLocationCity from '@iconify/icons-ic/twotone-location-city';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import icSmartphone from '@iconify/icons-ic/twotone-smartphone';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icMenu from '@iconify/icons-ic/twotone-menu';
import icCamera from '@iconify/icons-ic/twotone-camera';
import { map, startWith } from 'rxjs/operators';
import { ClienteService } from 'src/app/pages/cliente/services/cliente.service';

@Component({
  selector: 'vex-negocio-create-update',
  templateUrl: './negocio-create-update.component.html',
  styleUrls: ['./negocio-create-update.component.scss']
})
export class NegocioCreateUpdateComponent implements OnInit {

  static id = 100;

  clienteCtrl = new FormControl();
  clientes = [];

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  icMoreVert = icMoreVert;
  icClose = icClose;

  icPrint = icPrint;
  icDownload = icDownload;
  icDelete = icDelete;

  icPerson = icPerson;
  icMail = icMail;
  icMyLocation = icMyLocation;
  icLocationCity = icLocationCity;
  icEditLocation = icEditLocation;
  icPhone = icPhone;

  icCamera = icCamera;
  icMenu = icMenu;
  icArrowDropDown = icArrowDropDown;
  icSmartphone = icSmartphone;
  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<NegocioCreateUpdateComponent>,
    private clienteService: ClienteService,
    private fb: FormBuilder) {
      console.log(this.defaults);
      this.clientes = this.defaults.clientes;
    }

  ngOnInit() {
    if (this.defaults.negocio) {
      this.mode = 'update';
      this.defaults = this.defaults.negocio;
    } else {
      this.defaults = {} as Negocio;
    }

    this.form = this.fb.group({
      id: [NegocioCreateUpdateComponent.id++],
      nombre: [this.defaults.nombre || '', [Validators.required]],
      direccion: [this.defaults.direccion || '', [Validators.required]],
      telefono: [this.defaults.telefono || '', [Validators.required]],
      correo: [this.defaults.correo || '', [Validators.required]],
      localidad: [this.defaults.localidad || '', [Validators.required]],
      link: [this.defaults.link || '', [Validators.required]],
      descripcion: this.defaults.descripcion || ''
    });

    this.clienteCtrl.setValue(this.defaults.cliente);
  }

  filtered = this.clienteCtrl.valueChanges.pipe(
    startWith(''),
    map(cliente => cliente ? this.filterStates(cliente) : this.clientes.slice())
  );

  filterStates(nombre: any) {
    return this.clientes.filter(cliente => cliente.nombre.toLowerCase().indexOf(nombre.toLowerCase()) === 0);
  }

  save() {
    if (this.mode === 'create') {
      this.createNegocio();
    } else if (this.mode === 'update') {
      this.updateNegocio();
    }
  }

  createNegocio() {
    const negocio = this.form.value;
    negocio.cliente = this.clienteCtrl.value;
    this.dialogRef.close(negocio);
  }

  updateNegocio() {
    const negocio = this.form.value;
    negocio.id = this.defaults.id;
    negocio.cliente = this.clienteCtrl.value;
    this.dialogRef.close(negocio);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }
}
