import { Injectable } from '@angular/core';
import { Parse } from 'parse';
import { Negocio } from '../aio-table-negocio/interfaces/negocio.model';

@Injectable({ providedIn: 'root' })
export class NegocioService {
    constructor() { }

    async salvarNegocio(negocio: any) {
        return await Parse.Cloud.run("salvarNegocio", { negocio });
    }

    async actualizarNegocio(negocio: any) {
        return await Parse.Cloud.run("actualizarNegocio", { negocio });
    }

    async eliminarNegocio(negocioId: string) {
        return await Parse.Cloud.run("eliminarNegocio", { negocioId });
    }

    async getNegocios() {
        let negocios = new Array<Negocio>();
        const negociosParse = await Parse.Cloud.run("getNegocios");

        for (const iterator of negociosParse) {
            let negocio: Negocio = {
                id: iterator.id,
                nombre: iterator.get('info').nombre,
                direccion: iterator.get('info').direccion,
                telefono: iterator.get('info').telefono,
                correo: iterator.get('info').correo,
                etiquetas: [],
                localidad: iterator.get('localidad'),
                cliente: iterator.get('cliente'),
                descripcion: iterator.get('descripcion'),
                link: iterator.get('info').link
            }        
            
            negocios.push(negocio);
        }
        return negocios;
    }
}