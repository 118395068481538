import { Injectable } from '@angular/core';
import { Parse } from 'parse';
import { Customer } from '../aio-table-cliente/interfaces/customer.model';

@Injectable({ providedIn: 'root' })
export class ClienteService {
    constructor() { }

    async salvarCliente(cliente: any) {
        return await Parse.Cloud.run("salvarCliente", { cliente });
    }

    async actualizarCliente(cliente: any) {
        return await Parse.Cloud.run("actualizarCliente", { cliente });
    }

    async eliminarCliente(clienteId: string) {
        return await Parse.Cloud.run("eliminarCliente", { clienteId });
    }

    async getClientes() {
        let clientes = new Array<Customer>();
        const clientesParse = await Parse.Cloud.run("getClientes");

        for (const iterator of clientesParse) {
            let cliente: Customer = {
                id: iterator.id,
                imageSrc: iterator.get('imagen') ? iterator.get('imagen')._url : 'assets/img/defaultUser.png',
                nombre: iterator.get('info').nombre,
                apellidos: iterator.get('info').apellidos,
                direccion: iterator.get('info').direccion,
                telefono: iterator.get('info').telefono,
                correo: iterator.get('info').correo,
                etiquetas: [],
                ci: iterator.get('info').ci,
                correoPersonal: iterator.get('info').correoPersonal,
                localidad: iterator.get('localidad'),
                activo: iterator.get('activo'),
                porcientoAPagar: iterator.get('porcientoAPagar'),
                passwordAirbnb: iterator.get('passwordAirbnb'),
                passwordGmail: iterator.get('passwordGmail')
            }        
            
            clientes.push(cliente);
        }

        return clientes;
    }
}