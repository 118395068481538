import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Reserva } from '../interfaces/reserva.model';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPerson from '@iconify/icons-ic/twotone-person';
import icMail from '@iconify/icons-ic/mail';
import icMyLocation from '@iconify/icons-ic/twotone-my-location';
import icLocationCity from '@iconify/icons-ic/twotone-location-city';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import icSmartphone from '@iconify/icons-ic/twotone-smartphone';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icMenu from '@iconify/icons-ic/twotone-menu';
import icCamera from '@iconify/icons-ic/twotone-camera';
import { map, startWith } from 'rxjs/operators';
import { ClienteService } from 'src/app/pages/cliente/services/cliente.service';
import * as moment from 'moment';

@Component({
  selector: 'vex-reserva-create-update',
  templateUrl: './reserva-create-update.component.html',
  styleUrls: ['./reserva-create-update.component.scss']
})
export class ReservaCreateUpdateComponent implements OnInit {

  static id = 100;
  huespedes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  negocioCtrl = new FormControl();
  negocios = [];

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  icMoreVert = icMoreVert;
  icClose = icClose;

  icPrint = icPrint;
  icDownload = icDownload;
  icDelete = icDelete;

  icPerson = icPerson;
  icMail = icMail;
  icMyLocation = icMyLocation;
  icLocationCity = icLocationCity;
  icEditLocation = icEditLocation;
  icPhone = icPhone;

  icCamera = icCamera;
  icMenu = icMenu;
  icArrowDropDown = icArrowDropDown;
  icSmartphone = icSmartphone;
  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<ReservaCreateUpdateComponent>,
    private clienteService: ClienteService,
    private fb: FormBuilder) {
      console.log(this.defaults);
      this.negocios = this.defaults.negocios;
    }

  ngOnInit() {
    if (this.defaults.reserva) {
      this.mode = 'update';
      this.defaults = this.defaults.reserva;
    } else {
      this.defaults = {} as Reserva;
    }

    this.form = this.fb.group({
      id: [ReservaCreateUpdateComponent.id++],
      nombre: [this.defaults.nombre || '', [Validators.required]],
      cantidadPersonas: [this.defaults.cantidadPersonas || '', [Validators.required]],
      pais: [this.defaults.pais || '', [Validators.required]],
      fechaEntrada: [new Date(this.defaults.fechaEntrada) || '', [Validators.required]],
      fechaSalida: [new Date(this.defaults.fechaSalida) || '', [Validators.required]],
      fechaReserva: [new Date() || '', [Validators.required]],
      precio: [this.defaults.precio || '', [Validators.required]],
      datos: [this.defaults.datos || '']
    });

    this.negocioCtrl.setValue(this.defaults.negocioId);
  }

  filtered = this.negocioCtrl.valueChanges.pipe(
    startWith(''),
    map(negocio => negocio ? this.filterStates(negocio) : this.negocios.slice())
  );

  filterStates(nombre: any) {
    return this.negocios.filter(negocio => negocio.nombre.toLowerCase().indexOf(nombre.toLowerCase()) === 0);
  }

  save() {
    if (this.mode === 'create') {
      this.createReserva();
    } else if (this.mode === 'update') {
      this.updateReserva();
    }
  }

  createReserva() {
    const reserva = this.form.value;
    reserva.negocioId = this.negocioCtrl.value;
    this.dialogRef.close(reserva);
  }

  updateReserva() {
    const reserva = this.form.value;
    reserva.id = this.defaults.id;
    reserva.negocioId = this.negocioCtrl.value;
    this.dialogRef.close(reserva);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }
}
