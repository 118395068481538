import { Injectable } from '@angular/core';
import { Parse } from 'parse';
import { Reserva, Huesped } from '../aio-table-reserva/interfaces/reserva.model';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class ReservaService {
    constructor() { }

    async salvarReserva(reserva: any) {
        return await Parse.Cloud.run("salvarReserva", { reserva });
    }

    async actualizarReserva(reserva: any) {
        return await Parse.Cloud.run("actualizarReserva", { reserva });
    }

    async eliminarReserva(reservaId: string) {
        return await Parse.Cloud.run("eliminarReserva", { reservaId });
    }

    async getReservas() {
        let reservas = new Array<Reserva>();
        const reservasParse = await Parse.Cloud.run("getReservas");

        for (const iterator of reservasParse) {
            let reserva: Reserva = {
                id: iterator.id,
                negocioId: iterator.get('negocioId'),
                negocioNombre: iterator.get('negocioNombre'),
                datos: iterator.get('datos'),
                precio: iterator.get('precio'),
                cantidadPersonas: iterator.get('cantidadPersonas'),
                fechaEntrada: moment(iterator.get('fechaEntrada')).format('DD-MM-YYYY'),
                fechaSalida: moment(iterator.get('fechaSalida')).format('DD-MM-YYYY'),
                fechaReserva: moment(iterator.get('fechaReserva')).format('DD-MM-YYYY'),
                cobrado: false,
                horaLlegada:  iterator.get('horaLlegada'),
                evaluacionAnfitrion: iterator.get('evaluacionAnfitrion'),
                evaluacionHuesped: iterator.get('evaluacionHuesped'),
                canal: iterator.get('canal'),
                codigo: iterator.get('codigo'),
                link: iterator.get('link'),
                codigoPago: iterator.get('codigoPago'),
                montoPagado: iterator.get('montoPagado'),
                pagadoA: iterator.get('pagadoA'),
                etiquetas: [],
                nombre: iterator.get('nombre'),
                pais: iterator.get('pais'),
                telefono: iterator.get('telefono'),
                email: iterator.get('email'),
                emailAirbnb: iterator.get('emailAirbnb')
            }        
            
            reservas.push(reserva);
        }

        return reservas;
    }
}