import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import emojioneUS from '@iconify/icons-emojione/flag-for-flag-united-states';
import emojioneDE from '@iconify/icons-emojione/flag-for-flag-germany';
import icMenu from '@iconify/icons-ic/twotone-menu';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icDescription from '@iconify/icons-ic/twotone-description';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import { NavigationService } from '../../services/navigation.service';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import icSearch from '@iconify/icons-ic/twotone-search';
import { MatDialog } from '@angular/material/dialog';
import { ClienteService } from 'src/app/pages/cliente/services/cliente.service';
import { CustomerCreateUpdateComponent } from 'src/app/pages/cliente/aio-table-cliente/customer-create-update/customer-create-update.component';
import { Customer } from 'src/app/pages/cliente/aio-table-cliente/interfaces/customer.model';
import { Parse } from 'parse';
import { NegocioCreateUpdateComponent } from 'src/app/pages/negocio/aio-table-negocio/negocio-create-update/negocio-create-update.component';
import { NegocioService } from 'src/app/pages/negocio/services/negocio.service';
import { Negocio } from 'src/app/pages/negocio/aio-table-negocio/interfaces/negocio.model';
import { ReservaService } from 'src/app/pages/reserva/services/reserva.service';
import { Reserva } from 'src/app/pages/reserva/aio-table-reserva/interfaces/reserva.model';
import { ReservaCreateUpdateComponent } from 'src/app/pages/reserva/aio-table-reserva/reserva-create-update/reserva-create-update.component';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  navigationItems = this.navigationService.items;

  isHorizontalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));

  icSearch = icSearch;
  icBookmarks = icBookmarks;
  emojioneUS = emojioneUS;
  emojioneDE = emojioneDE;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icDescription = icDescription;
  icAssignment = icAssignment;
  icReceipt = icReceipt;
  icDoneAll = icDoneAll;
  icArrowDropDown = icArrowDropDown;

  constructor(private layoutService: LayoutService,
    private configService: ConfigService,
    private navigationService: NavigationService,
    private popoverService: PopoverService,
    private dialog: MatDialog,
    private apiService: ClienteService,
    private negocioService: NegocioService,
    private reservaService: ReservaService,
  ) { }

  ngOnInit() {
  }

  openQuickpanel() {
    this.layoutService.openQuickpanel();
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement) {
    this.popoverService.open({
      content: MegaMenuComponent,
      origin,
      position: [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });
  }

  openSearch() {
    this.layoutService.openSearch();
  }

  addCliente() {
    this.dialog.open(CustomerCreateUpdateComponent).afterClosed().subscribe(async (customer: Customer) => {
      /**
       * Customer is the updated customer (if the user pressed Save - otherwise it's null)
       */
      if (customer) {
        let imagenCliente = null;
        if (customer.imageSrc) {
          imagenCliente = new Parse.File(customer.nombre + '.png', { base64: customer.imageSrc });
          let imagenObject = await imagenCliente.save({ useMasterKey: true });
          customer.imageSrc = imagenObject;
        }
        if (imagenCliente === null) {
          customer.imageSrc = null;
        }
        this.apiService.salvarCliente(customer).then((cliente) => {
          if (cliente.get('imagen')) {
            customer.imageSrc = cliente.get('imagen')._url;
          }
          else {
            customer.imageSrc = 'assets/img/defaultUser.png';
          }
        }).catch((error) => {
          alert(error);
        });
      }
    });
  }

  async addNegocio() {

    this.apiService.getClientes().then((clientes) => {
      this.dialog.open(
        NegocioCreateUpdateComponent,
        {
          data: { negocio: null, clientes: clientes },
          width: '60%',
        }
      ).afterClosed().subscribe(async (negocio: Negocio) => {
        if (negocio) {
          this.negocioService.salvarNegocio(negocio).then(() => {
          }).catch((error) => {
            alert(error);
          });
        }
      });
    }).catch((error) => {
      alert(error);
    });


  }

  addReserva() {
    this.negocioService.getNegocios().then((negocios) => {
      this.dialog.open(
        ReservaCreateUpdateComponent,
        {
          data: { reserva: null, negocios: negocios },
          width: '60%',
        }
      ).afterClosed().subscribe(async (reserva: Reserva) => {
        if (reserva) {
          for (const iterator of negocios) {
            if (iterator.id === reserva.negocioId) {
              reserva.negocioNombre = iterator.nombre;
            }
          }
          this.reservaService.salvarReserva(reserva).then(() => {
          }).catch((error) => {
            alert(error);
          });
        }
      });
    }).catch((error) => {
      alert(error);
    });
  }
}
