// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //PROD
  PARSE_APP_ID: 'v4ChzHYidlTsfztTQPhzwz3Fu49K03Os6uuLisWW',
  PARSE_JS_KEY: 'lXl5yWllOhmWhR0TdFV0bEczgu9wkRnJfxa5MX7y',
  PARSE_MASTER_ID: 'fXWB7jc5hadhGQbxeHET4tiHn9PGyPdKUHWZxJsy',  
  serverURL: 'https://parseapi.back4app.com'


  //DEV
  // PARSE_APP_ID: 'sI5pX6LpcjlcZbwaE5WYfDJjAFeyWP6dm08T5S7Y',
  // PARSE_JS_KEY: '0tSWC8VzJVBlvAEDlM20m2hjXvlBMFdhM8kcG0Ly',
  // PARSE_MASTER_ID: 'xMjW7zrNse7XbWe30Rui1tZVywhnxQabs2PHSMyw',
  // serverURL: 'https://parseapi.back4app.com'
  // liveQueryServerURL: 'wss://pmcsprodclonedev.b4a.io'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
