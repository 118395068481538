<form (ngSubmit)="save()" [formGroup]="form">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>

    <h2 *ngIf="form.get('nombre').value"
        class="headline m-0"
        fxFlex="auto">{{ form.get('nombre').value }}</h2>
    <h2 *ngIf="!form.get('nombre').value"
        class="headline m-0"
        fxFlex="auto">Nuevo Negocio</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-content fxLayout="column">
    <mat-form-field>
      <mat-label>Nombre</mat-label>
      <input cdkFocusInitial formControlName="nombre" matInput>

      <mat-icon [icIcon]="icPerson" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Dirección</mat-label>
      <input formControlName="direccion" matInput>

      <mat-icon [icIcon]="icEditLocation" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Teléfono</mat-label>
      <input formControlName="telefono" matInput>

      <mat-icon [icIcon]="icPhone" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Localidad</mat-label>
      <input formControlName="localidad" matInput>

      <mat-icon [icIcon]="icEditLocation" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Correo</mat-label>
      <input formControlName="correo" matInput>

      <mat-icon [icIcon]="icMail" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Link</mat-label>
      <input formControlName="link" matInput>

      <mat-icon [icIcon]="icMail" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Descripción</mat-label>
      <input formControlName="descripcion" matInput>

      <mat-icon [icIcon]="icMail" class="mr-3" matPrefix></mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Cliente</mat-label>
      <input [matAutocomplete]="auto" [formControl]="clienteCtrl" matInput>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let cliente of filtered | async " [value]="cliente.id">
          <img [src]="cliente.imageSrc" class="align-middle mr-4 shadow-6 imagen-select-cliente">
          <span class="body-1">{{ cliente.nombre }}</span>
          <!-- <span class="text-hint"> | </span>
          <span class="caption text-secondary">Population: {{ state.population }}</span> -->
        </mat-option>
      </mat-autocomplete>

      <button class="text-secondary" mat-icon-button matSuffix type="button">
        <mat-icon [icIcon]="icArrowDropDown"></mat-icon>
      </button>

      <mat-hint>Seleccione el dueño del negocio</mat-hint>
    </mat-form-field>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">CANCELAR</button>
    <button *ngIf="isCreateMode() && form.valid " color="primary" mat-button type="submit">CREAR</button>
    <button *ngIf="isUpdateMode()" color="primary" mat-button type="submit">ACTUALIZAR</button>
  </mat-dialog-actions>
</form>

<!-- <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon [icIcon]="icPrint"></mat-icon>
    <span>Print</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icDownload"></mat-icon>
    <span>Export</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icDelete"></mat-icon>
    <span>Delete</span>
  </button>
</mat-menu> -->
